import React, { useEffect } from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Heading,
  Text,
  Width,
  CTA,
  ListItem,
  List,
  VideoPlayer,
} from "@/components"
import { color } from "@/theme/index"
import Img from "gatsby-image"
import styled from "styled-components"
import { breakpoints } from "@/theme/index"

export default function Application({ data }) {
  return (
    <Layout
      headerType="checkout"
      headerTheme="light"
      footerTheme="light"
      squeeze={true}
    >
      <SEO
        title="Let's start your guitar Journey"
        description="Sonora Beginner Bootcamp is the fastest way to jumpstart your playing and finally understand the guitar."
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={["7.5rem", null, null, "9.6rem"]}
        pb={["4.8rem", null, null, "0"]}
      >
        <Width
          display="flex"
          flexDirection="column"
          pt={["7.2rem", null, null, "2.2rem"]}
          pb={["0", null, null, "6.9rem"]}
        >
          <Heading
            mb="2rem"
            level="2"
            mt="3rem"
            pr={["0rem", "0rem", "2rem", "7rem"]}
          >
            <strong>Wanted:</strong> Beginner Guitarists Who Are Almost (but not
            quite) Hitting a Ceiling With Their Playing. Their reward?{" "}
            <strong>
              Nail the solo, noticeable phrasing, & intricate improvisation{" "}
            </strong>{" "}
            in just <strong>6 weeks</strong> from today
          </Heading>
          <Box
            width={["100%", 12 / 12]}
            textAlign="left"
            mb={["2rem", null, null, "0rem"]}
            pr={["0", "0rem"]}
          >
            <VideoPlayer
              width="auto"
              maxWidth="auto"
              url="https://vimeo.com/793051028/654397873d"
            />
          </Box>
          <Box
            width={["100%", 7 / 12]}
            mt="3rem"
            mb="1rem"
            mx="auto"
            textAlign="center"
          >
            <List bullets="checkmark">
              <ListItem color="tanzanite">
                <Text>
                  Active discount applied on this page only:{" "}
                  <strong>Save $252 today </strong>
                </Text>
              </ListItem>
              <ListItem color="tanzanite">
                <Text>
                  {" "}
                  Only 10 spots available. If this page is still active, claim
                  yours before 8/14/23{" "}
                </Text>
              </ListItem>
              <ListItem color="tanzanite">
                <Text>
                  {" "}
                  Lifetime guarantee, lifetime material access, FIVE (5) full
                  free bonuses{" "}
                </Text>
              </ListItem>
            </List>
          </Box>

          <Box mb={["2rem", null, null, "0"]}>
            <CTA
              variant="special"
              to="https://buy.stripe.com/28o6qp0fvb5ydDW007"
              mb="1rem"
              mt="3rem"
              fontSize="2em"
            >
              <Text level="1">Claim your Bootcamp™ Spot Now</Text>
            </CTA>
          </Box>
        </Width>
        <Width>
          <Box
            width={["100%", null, null, 12 / 12]}
            textAlign="left"
            mx="auto"
            pr={["2rem", "2rem", "2rem", "3rem"]}
          >
            <Heading
              mb="1rem"
              mt="1rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
              level="2"
            >
              Dear Musicians
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If you’re still just about hanging together with your favorite
              songs…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If you’re still not able to play the riffs, solos, and licks on
              the albums you’ve wanted to master for an age…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If you’re still watching musicians you admire and wondering just
              how they achieve it despite being younger, or even playing for
              less time…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Then I want you to invite you to work alongside me, and if you
              choose to accept it, I’ve applied an active discount below saving
              you $252, and over 50% off on this page.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              BUT, I can’t hold it for long. Let me explain why, because in
              exactly…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              … we’re about to launch our{" "}
              <strong>Sonora Beginner Bootcamp™</strong> where for 6 full weeks,
              you will join me live as a mentor so we smooth out the kinks and
              seriously advance your playing.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              The goal?
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Take you from a beginner or advanced beginner, and place you
              firmly among the ranks of an intermediate, quality guitar player.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              This may be all you need in your playing frankly. But regardless,
              through the <strong>Bootcamp™</strong>, you’ll have everything you
              need to see the journey from where you are now, to where you’re{" "}
              <strong>creatively free</strong>.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              To most of our students, that looks like:
            </Text>
            <Box mb="2rem">
              <List bullets="checkmark">
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Intermediate Level</strong>: Owning a growing song
                    repertoire collection, nailing the “bits and pieces” you
                    missed before, and beginning to improvize where you couldn’t
                    before{" "}
                  </Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Upper Intermediate Level</strong>: Advancing your
                    solos, and improvizing over songs and backing tracks that
                    puts a smile on your face (especially when playing over key
                    changes, and nailing “outside notes”){" "}
                  </Text>
                </ListItem>
                <ListItem color="tanzanite">
                  <Text>
                    <strong>Pro / Uncapped Level</strong>: Stepping on stage (if
                    that’s even your thing), and playing among other musicians
                    and band members with comfort,{" "}
                    <strong>no matter where they take the song.</strong>
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              So while we work together for 6 weeks (42 days / 1.4 months),{" "}
              <strong>we will achieve quantum leaps in your playing</strong>. In
              fact, together we’ll put the strongest possible foundations in
              your technique, theory, and sonic understanding so you walk away
              seeing the full journey in front of you.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Remember, the map is NOT the territory. Instead, we want to map
              out your best path to fluency, freedom, and phrasing that piques
              anyone’s ear who catches you playing.
            </Text>
            <Heading
              textAlign="center"
              mb="1rem"
              mt="3rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
              level="2"
            >
              The Investment is LOW: And will outsail any guitar teacher due to
              our systems:
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If the average guitar teacher is $50 per hour, for just 5 lessons
              of their time, I will have you blow past anything you would
              otherwise achieve in the time. That I’ll promise and guarantee
              here.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              In a nutshell, ALL I want to do is map out your needs as a guitar
              player, and install feedback loops into your learning{" "}
              <strong>that I will oversee</strong>. However, going deeper, we
              use…
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>Path → </strong>Mapping out the entire beginner >
              intermediate > beyond path, looking at the typical
              roadblocks/bottlenecks, and installing efficient practice to reach
              the next level faster. We’ll individually tailor this to your
              playing and your level.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>Process → </strong>Together we’ll map out a process -
              custom to you - that allows you to stretch your fingers, brain,
              mind, and ears in ways you never otherwise knew to do. It puts you
              out of your comfort zone, challenges your theory, breaks down the
              fretboard from a logical and emotional perspective, and lets you
              grow on the instrument within 20-40 minutes per day.{" "}
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              <strong>People → </strong>With the above 2 steps complete, all we
              have to do is manage and gauge your progress, and then redirect
              your efforts in areas you’re strong and weak. Using mentorship &
              live classes, we’ll skip bad habits, and dial in on what’s
              producing noticeable comfort in your playing all over the neck.{" "}
            </Text>

            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              And the investment? Usually the <strong>Bootcamp™</strong> is $499
              for the entire 6 week investment. However I’ve had numerous
              students reach out for a scholarship spot which I will honor.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              So the next 10 students who join will receive the active discount
              on this page, save $252 (50% off), and work alongside me to build
              chops and playing that catches anyone’s ear. Plus…
            </Text>

            <Heading
              level="2"
              textAlign="center"
              mb="1rem"
              mt="3rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
            >
              For the Lifetime of your Playing…
            </Heading>
            <Heading
              level="2"
              textAlign="center"
              mb="1rem"
              mt="1rem"
              pr={["0rem", "0rem", "2rem", "7rem"]}
            >
              I will honor the following guarantee
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              In the unlikely event you join the <strong>Bootcamp™</strong> and
              feel it’s not for you, or you can’t commit to the process, for any
              reason at all, then I will hand back your small investment in FULL
              immediately. But I’m going to take it up a level here.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              If you decide to simply put this system to the test, I will
              guarantee your results for the lifetime that you complete the
              program.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Just attend at least FOUR (4) live classes at minimum, and
              participate in a handful of events. That’s it.
            </Text>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              I’ll put my trust into you that if you do these things, and for
              any reason you’re still unhappy, I will return your investment in
              the
              <strong>Bootcamp™</strong> at any time.
            </Text>
            <Text mb="4rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              That means you don’t need to judge this over the next 42 days. You
              can hold it to the test that it makes your playing better, or you
              simply don’t pay.
            </Text>
          </Box>
        </Width>

        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["1.2rem", null, null, "5.2rem"]}
          pb={["0", null, null, "2.9rem"]}
        >
          <Box
            width={["100%", null, null, 6 / 12]}
            textAlign="left"
            mx="auto"
            pr={["2rem", "2rem", "2rem", "3rem"]}
          >
            <Heading level="xl" mb="1rem" mt={["3rem", "3rem", "3rem", "1rem"]}>
              Let's start your Guitar Journey
            </Heading>
            <Text mb="1rem" mt="3rem" pr={["0rem", "0rem", "2rem", "7rem"]}>
              Sonora Beginner Bootcamp is the <strong>fastest</strong> way to
              jumpstart your playing and finally understand the guitar.
            </Text>
            <Text
              level="2"
              mb={["5rem", "5rem", "5rem", "5rem"]}
              mt="3rem"
              pr={["0rem", "2rem", "2rem", "4rem"]}
            >
              Sonora's 6-week online beginner course is designed to take
              participants with little-to-some guitar experience to
              intensive-ready under the instruction of world-class mentors.
            </Text>
            <HideCard>
              <Box mb="3em">
                <CTA variant="special" to="#survey">
                  {" "}
                  Get Started
                </CTA>
              </Box>
            </HideCard>
            <Box>
              <Box
                width={["100%", null, 12 / 12, null]}
                display="flex"
                flexDirection="row"
                mt={["0rem", null, null, "3rem"]}
              >
                <Box width={[3 / 12, null]} pr="2rem">
                  <Box borderRadius="100%" overflow="hidden">
                    <Img
                      alt={"david"}
                      fluid={data.davidEImage.childImageSharp.fluid}
                    />
                  </Box>
                </Box>
                <Text
                  width={["100%", null, 9 / 12, null]}
                  level="2"
                  mb="1rem"
                  pr={["2rem", "2rem", "2rem", "4rem"]}
                >
                  "This is the most thoughtful, innovative, and intelligent
                  methodology for internalizing not only fretboard fluency, but
                  music in general." <br></br>
                  <strong>- David Engelhardt, Sonora Alumni</strong>
                </Text>
              </Box>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"milton"}
                    fluid={data.miltonPImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "This guitar learning program is by far the best one ever
                conceptualized." <br></br>
                <strong>- Milton Pizarro, Sonora Alumni</strong>
              </Text>
            </Box>
            <Box
              width={["100%", null, 12 / 12, null]}
              display="flex"
              flexDirection="row"
              mt="3rem"
              mb="3rem"
            >
              <Box width={[3 / 12, null]} pr="2rem">
                <Box borderRadius="100%" overflow="hidden">
                  <Img
                    alt={"kevin"}
                    fluid={data.katieBImage.childImageSharp.fluid}
                  />
                </Box>
              </Box>
              <Text
                width={["100%", null, 9 / 12, null]}
                level="2"
                mb="1rem"
                pr={["2rem", "2rem", "2rem", "4rem"]}
              >
                "It was an incredibly worthwhile investment that helped me break
                out of a years long rut as a writer and player." <br></br>
                <strong>- Katie Boeck, Sonora Alumni</strong>
              </Text>
            </Box>
          </Box>

          <Box width={["90%", null, null, 6 / 12]} id="survey" mx="auto">
            <Box
              backgroundImage="linear-gradient(79.88deg, #5127AE 27.59%, #D04556 100.28%)"
              borderRadius=".8rem"
              width={["100%", "80%", 12 / 12, null]}
              mx="auto"
            >
              <Box
                width={"100%"}
                borderRadius=".6rem"
                textAlign="center"
                mb={["0rem", null, "0"]}
                bg={color.opal}
                ml={["auto", null, 0, null]}
                mr={["auto", null, "1.8rem", null]}
              >
                <Box
                  width={"100%"}
                  p="2.4rem"
                  borderRadius=".6rem"
                  textAlign="center"
                  mb={"0rem"}
                >
                  <Heading
                    mb=".8rem"
                    mx={["auto", 0, null, null]}
                    color={color.tanzanite}
                    textAlign="center"
                  >
                    Sonora Beginner Bootcamp
                  </Heading>
                  <Text
                    textAlign="center"
                    color={color.tanzanite}
                    fontSize="19px"
                    mb="1rem"
                  >
                    10-Week Course for Beginners
                  </Text>
                  <Text mt="0em">
                    <em>
                      <strong>Next Cohort Begins: 4/1/24</strong>
                    </em>
                  </Text>
                  <Box textAlign="left">
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.tanzanite}
                      mb="2rem"
                      mt="2rem"
                      textAlign="center"
                    >
                      INCLUDED
                    </Text>
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        Structured curriculum with custom practice plans
                      </ListItem>
                      <ListItem color="tanzanite">
                        20 Live Classes (Replays Available)
                      </ListItem>
                      <ListItem color="tanzanite">
                        Unlimited access to our team and community
                      </ListItem>
                      <ListItem color="tanzanite">
                        Structured assignments and projects
                      </ListItem>
                      <ListItem color="tanzanite">
                        Exclusive Facebook Community{" "}
                      </ListItem>
                      {/* <ListItem color="tanzanite">
                        {" "}
                        $500 Tuition Credit for Sonora Guitar Intensive
                        Intermediate Program
                      </ListItem> */}
                    </List>
                  </Box>

                  <Box textAlign="left">
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.tanzanite}
                      mb="2rem"
                      mt="2rem"
                      textAlign="center"
                    >
                      BONUSES
                    </Text>
                    <List bullets="checkmark">
                      <ListItem color="tanzanite">
                        BONUS #1 - Accelerated Learning Module
                      </ListItem>
                      <ListItem color="tanzanite">
                        BONUS #2 - FREE Practice Buddy Pass
                      </ListItem>
                      <ListItem color="tanzanite">
                        BONUS #3 - Songwriting Masterclass
                      </ListItem>
                      <ListItem color="tanzanite">
                        BONUS #4 - Improvisors Toolkit
                      </ListItem>
                      <ListItem color="tanzanite">
                        BONUS #5 - $1000+ Gear Discounts
                      </ListItem>
                      {/* <ListItem color="tanzanite">
                        {" "}
                        $500 Tuition Credit for Sonora Guitar Intensive
                        Intermediate Program
                      </ListItem> */}
                    </List>
                  </Box>
                 
                </Box>
                <Box
                  backgroundImage="linear-gradient(72.5deg, #651FFF 0%, #D04556 99.51%)"
                  color="white"
                  py="2rem"
                  borderBottomRightRadius=".6rem"
                  borderBottomLeftRadius=".6rem"
                >
                  <>
                    <Box
                      display="flex"
                      mb="0.6rem"
                      alignItems="center"
                      justifyContent={["center", null]}
                    >
                      <h4>
                        <SmallNumber>$500</SmallNumber>
                      </h4>
                    </Box>
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      color={color.white}
                      mb=".6rem"
                      textAlign="center"
                    >
                      10 SEATS AVAILABLE
                    </Text>
                  </>
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={["center", null]}
                  >
                    <h4>
                      <Dollar>$ </Dollar>
                      <Number>247</Number>{" "}
                    </h4>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <ApplicationCard>
              <Heading level="2" mb={["5rem", null, null, "2rem"]}>
                Tell us about yourself
              </Heading>
              <div id="hubspotForm"></div>
            </ApplicationCard> */}
          </Box>
        </Width>
        <Box mb={["2rem", null, null, "0"]}>
          <CTA
            variant="special"
            to="https://buy.stripe.com/28o6qp0fvb5ydDW007"
            mb="1.2rem"
            mt="0rem"
            fontSize="2em"
          >
            <Text level="1">Claim your Bootcamp™ Spot Now</Text>
          </CTA>
        </Box>
      </Box>
    </Layout>
  )
}

const ApplicationCard = styled.div`
  background-color: white;
  border-radius: 0.5em;
  padding: 1em;
`
const HideCard = styled.div`
  @media (min-width: ${breakpoints[1]}) {
    display: none;
  }
`

/* Image and Other GQL Queries */
export const applicationQuery = graphql`
  query {
    davidEImage: file(relativePath: { eq: "davide.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    kevinSImage: file(relativePath: { eq: "kevins.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    miltonPImage: file(relativePath: { eq: "miltonp.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    katieBImage: file(relativePath: { eq: "katieb.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    erikLImage: file(relativePath: { eq: "erikl.png" }) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const Number = styled.span`
  font-size: 4.2rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`

const Dollar = styled.span`
  font-weight: 300;
  font-size: 3.4rem;
  line-height: 1.16;
  letter-spacing: 0.01em;
  color: rgba(179, 168, 203, 0.5);
`

const SmallNumber = styled.span`
  font-size: 2.2rem;
  text-decoration: line-through;
  line-height: 1.16;
  opacity: 0.5;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
`
